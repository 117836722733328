var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"news-list"},[_c('header',{staticClass:"page-header"},[_c('van-icon',{attrs:{"name":"arrow-left"},on:{"click":function($event){return _vm.$router.go(-1)}}}),_c('span',[_vm._v("系统通知")]),_c('span')],1),_c('main',{staticClass:"page-body"},[_c('ul',{staticClass:"list"},[(_vm.list.length > 0)?_c('li',_vm._l((_vm.list),function(it){return _c('section',{key:it.PK_ID,staticClass:"list-item flex-box",on:{"click":function($event){return _vm.$router.push({
              name: 'NotifyDetail',
              query: { id: it.PK_ID },
            })}}},[_c('div',{staticClass:"thumbnail"},[_c('img',{attrs:{"src":it.sImage != null
                  ? it.sImage.startsWith('http')
                    ? it.sImage
                    : _vm.baseUrl + it.sImage
                  : _vm.emptyImg}})]),_c('div',{staticClass:"item-body"},[_c('div',{staticClass:"title f-16"},[_vm._v(_vm._s(it.sTitle))]),_c('div',{staticClass:"desc f-16"},[_vm._v(_vm._s(it.sContent))]),_c('div',{staticClass:"brief"},[_c('span',{staticClass:"pull-right"},[_c('font-awesome-icon',{attrs:{"icon":"clock"}}),_vm._v(" "+_vm._s(it.dCreateTime)+" ")],1)])])])}),0):_c('li',[_c('section',{staticClass:"padding no-item"},[_c('div',{},[_c('img',{attrs:{"src":_vm.emptyImg}})]),_c('div',{staticClass:"nodesc"},[_vm._v("没有相关的信息")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }