<template>
  <div class="news-list">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>系统通知</span>
      <span></span>
    </header>

    <main class="page-body">
      <ul class="list">
        <li v-if="list.length > 0">
          <section
            class="list-item flex-box"
            v-for="it in list"
            :key="it.PK_ID"
            @click="
              $router.push({
                name: 'NotifyDetail',
                query: { id: it.PK_ID },
              })
            "
          >
            <div class="thumbnail">
              <img
                :src="
                  it.sImage != null
                    ? it.sImage.startsWith('http')
                      ? it.sImage
                      : baseUrl + it.sImage
                    : emptyImg
                "
              />
            </div>
            <div class="item-body">
              <div class="title f-16">{{ it.sTitle }}</div>
              <div class="desc f-16">{{ it.sContent }}</div>
              <div class="brief">
                <span class="pull-right"
                  ><font-awesome-icon icon="clock" />
                  {{ it.dCreateTime }}
                </span>
              </div>
            </div>
          </section>
        </li>
        <li v-else>
          <section class="padding no-item">
            <div class=""><img :src="emptyImg" /></div>
            <div class="nodesc">没有相关的信息</div>
          </section>
        </li>
      </ul>
    </main>
  </div>
</template>
<script>
import { getNotifyList } from '@/api/website.js';
import { BASE_URL } from '@/api/index.js';
import { formatDate } from '@/utils/index.js';
function getValidTime(value) {
  if (value == null || value == '') return '';
  var myDate = new Date(value.match(/\d+/)[0] * 1);
  return formatDate(myDate);
}
export default {
  data() {
    return {
      list: [],
      emptyImg: require('../../assets/images/noinfo.png'),
      baseUrl: BASE_URL,
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      getNotifyList(20).then(res => {
        console.log(res.DataDict['data']);
        this.list = res.DataDict['data'];
        this.list.forEach(it => {
          it.dCreateTime = getValidTime(it.dCreateTime);
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.page-body {
  padding: rem(40px);
  background: #fff;
}
.list-item {
  border-bottom: 1px solid #b8b8b8;
  padding: rem(20px) 0;
  width: 100%;
  height: 1.1rem;
  .desc {
    color: #969696;
    font-size: rem(16px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.title {
  font-size: rem(26px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.thumbnail {
  width: rem(120px);
  height: rem(120px);
  margin-right: rem(40px);
  position: relative;
  float: left;
  img {
    border-radius: rem(10px);
    width: rem(120px);
    height: rem(110px);
    border: 1px solid #b8b8b8;
  }
}

.brief {
  font-size: 12px;
  margin-top: rem(30px);
  color: #b8b8b8;
}

.no-item {
  text-align: center;
  margin: rem(40px);
  img {
    height: rem(149px);
  }
  .no-desc {
    font-size: rem(13px);
    text-align: center;
  }
}
</style>
